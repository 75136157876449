"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInchesFromLength = void 0;
var apis_1 = require("@digital-motors-boatyard/common/dist/apis");
var math_utilities_1 = require("@digital-motors-boatyard/math-utilities");
var getInchesFromLength = function (nullableLength) {
    if (nullableLength.unit === apis_1.LengthUnit.INCH) {
        return (nullableLength.value && Math.round(nullableLength.value)) || 0;
    }
    if (nullableLength.unit === apis_1.LengthUnit.METER) {
        return (0, math_utilities_1.metersToInches)(nullableLength.value);
    }
    return 0;
};
exports.getInchesFromLength = getInchesFromLength;
