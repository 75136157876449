"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformIncentivesForDealSheet = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var transformIncentivesForDealSheet = function (_a) {
    var incentives = _a.incentives;
    return incentives
        .filter(function (incentive) {
        var amount = Number(incentive.amount);
        return amount && !isNaN(amount);
    })
        .map(function (incentive) { return ({
        incentiveId: incentive.id,
        lenderId: incentive.source.type === enums_1.IncentiveSourceType.LENDER
            ? incentive.source.id
            : undefined,
        name: incentive.source.programName || incentive.name,
        amount: Number(incentive.amount),
        type: incentive.source.type,
        incentiveType: incentive.type,
        sourceRegionId: null,
    }); });
};
exports.transformIncentivesForDealSheet = transformIncentivesForDealSheet;
