import { Country } from '@digital-motors-boatyard/common/dist/enums';
import { DealSheetSummary } from '@digital-motors-boatyard/common/dist/interfaces';
import {
  exactCharacterLength,
  FormattedField,
  isValidPhoneForCountry,
  useForm,
} from '@digital-motors-boatyard/components.form';
import { ModalHeader } from '@digital-motors-boatyard/components.modal';
import { phoneFormat } from '@digital-motors-boatyard/ui.input';
import { spacing, styled } from '@digital-motors-boatyard/ui.theme';
import { FC, useEffect, useRef, useState } from 'react';

import { sendPhoneValidation } from '../../api/sendPhoneValidation';
import { EventParams, useAnalytics } from '../../context/Analytics';
import { useAppData } from '../../context/AppDataContext';
import { color } from '../../lib/color';
import { ErrorMessage, FieldsWrapper, InfoMessage } from '../../styles';
import { HeaderDetails } from '../Header';
import { PaymentSummaryDetails } from '../PaymentSummary';
import { PillButton } from '../PillButton';
import { ReEntryDisclaimer } from './ReEntryDisclaimer';
import { ReEntryView } from './ReEntryModal';

interface Props {
  setView: (view: ReEntryView) => void;
  setPhone: (phone: string) => void;
  onCancel: () => void;
  dealSummary: DealSheetSummary;
  eventParams: EventParams;
  visible: boolean;
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${color('divider')};
  padding: ${spacing.xs} 0 0;
  margin: ${spacing.xs} 0;
`;

export const SignInForm: FC<Props> = ({
  setView,
  setPhone,
  onCancel,
  dealSummary,
  eventParams,
  visible,
}) => {
  const { trackEvent } = useAnalytics();
  const initialEventFired = useRef(false);
  const { registerSubmitHandler, registerField, getValues, validate } =
    useForm<{
      phoneNumber: string;
    }>();
  const { tenant } = useAppData();
  const { id: tenantId, country } = tenant;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const onSubmit = registerSubmitHandler(async () => {
    const values = getValues();
    const isValid = validate(values);
    if (!values || !isValid) return;
    setIsSubmitting(true);
    setSubmissionError('');
    const res = await sendPhoneValidation({
      phoneNumber: values.phoneNumber,
      tenantId,
      country,
    });
    setPhone(values.phoneNumber);
    trackEvent('rider_reenrty_submit', eventParams);
    if (res.success) {
      setView('verify');
    } else {
      setSubmissionError(res.errorMessage || 'An error occurred');
    }
    setIsSubmitting(false);
  });

  useEffect(() => {
    if (!visible || initialEventFired.current) return;
    trackEvent('rider_reenrty', eventParams);
    initialEventFired.current = true;
  }, [eventParams, trackEvent, visible]);

  return (
    <>
      <ModalHeader
        colorScope="sidebars.rider"
        title={`Welcome, ${dealSummary.userFirstName}`}
        leftButtonText="Cancel"
        leftButtonAction={onCancel}
        noLine
      />
      <InfoMessage>Enter your mobile number to access your deal</InfoMessage>
      <FieldsWrapper margin="0">
        <FormattedField
          {...registerField('phoneNumber', {
            dataType: 'numericString',
            validators: [
              exactCharacterLength(10, 'Invalid phone number'),
              isValidPhoneForCountry([
                Country.UNITED_STATES,
                Country.CANADA,
                country as Country,
              ]),
            ],
          })}
          {...phoneFormat}
          label="Mobile Number"
          data-testid="SignInForm__ReEntry__Phone"
          helperText="Standard message and data rates apply."
          readOnly={isSubmitting}
        />
      </FieldsWrapper>
      <HeaderDetails deal={dealSummary} signInUser={() => {}} infoOnly />
      <PaymentSummaryDetails deal={dealSummary} />
      {submissionError && <ErrorMessage>{submissionError}</ErrorMessage>}
      <ButtonWrapper>
        <PillButton
          data-testid="SignInForm__ReEntry__Continue"
          variant="accent"
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          Continue
        </PillButton>
      </ButtonWrapper>
      <ReEntryDisclaimer />
    </>
  );
};
