import { useRiderComponent } from '@digital-motors-boatyard/by-vessel-rider.component';
import { formatCurrency } from '@digital-motors-boatyard/common/dist/helpers/formatCurrency';
import {
  DealSheet,
  DealSheetSummary,
} from '@digital-motors-boatyard/common/dist/interfaces';
import { Button } from '@digital-motors-boatyard/ui.button';
import { Text } from '@digital-motors-boatyard/ui.text';
import { spacing, styled } from '@digital-motors-boatyard/ui.theme';
import numeral from 'numeral';
import { FC } from 'react';

import { CASH, FINANCE } from '../constants';
import { useIsInverted } from '../hooks/useIsInverted';
import { color } from '../lib/color';
import { SkeletonText } from './Skeleton';

const Container = styled.div`
  margin: ${spacing.units(6)} 0;

  :first-of-type {
    margin-top: ${spacing.xs};
  }
`;

const PrimaryRow = styled(Text)`
  color: ${color('heading')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
PrimaryRow.defaultProps = {
  variant: 'heading6',
};

const SecondaryRow = styled(Text)`
  color: ${color('subtle')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span:first-of-type {
    padding-right: ${spacing.xxxs};
  }
`;
SecondaryRow.defaultProps = {
  variant: 'small',
};

const CustomizePayment = styled(Button)`
  color: ${color('subtle')};
  padding: 0;
  height: auto;
  text-align: right;
  text-decoration: underline;
  box-shadow: none !important;
`;
CustomizePayment.defaultProps = {
  variant: 'none',
  size: 's',
};

interface Props {
  onCustomize?: () => void;
}

export const PaymentSummarySkeleton: FC = () => (
  <Container>
    <PrimaryRow>
      <SkeletonText boxWidth="40%" />
      <SkeletonText boxWidth="25%" />
    </PrimaryRow>
    <SecondaryRow>
      <SkeletonText variant="small" boxWidth="50%" />
      <SkeletonText variant="small" boxWidth="35%" />
    </SecondaryRow>
  </Container>
);

export const PaymentSummaryDetails: FC<
  Props & {
    deal: DealSheet | DealSheetSummary;
  }
> = ({ onCustomize, deal }) => {
  const isInverted = useIsInverted();
  const { financeType, downPayment, term, apr, salesPrice, estimatedPayment } =
    deal;

  if (financeType === FINANCE)
    return (
      <Container>
        <PrimaryRow>
          <span>Finance Payment</span>
          <span data-testid="PaymentSummary__Finance__EstimatedPayment">
            {formatCurrency(estimatedPayment || 0)} /mo
          </span>
        </PrimaryRow>
        <SecondaryRow>
          <span data-testid="PaymentSummary__Apr">
            {formatCurrency(downPayment || 0)} down &bull; {term} months &bull;{' '}
            {numeral(apr).format('0[.][0]0%')} APR
          </span>
          {!!onCustomize && (
            <CustomizePayment
              data-testid="CustomizePayment__Button"
              onClick={onCustomize}
              inverted={isInverted}
            >
              Customize Payment
            </CustomizePayment>
          )}
        </SecondaryRow>
      </Container>
    );
  if (financeType === CASH)
    return (
      <Container>
        <PrimaryRow>
          <span>Sales Price</span>
          <span data-testid="PaymentSummary__Cash__SalesPrice">
            {formatCurrency(salesPrice || 0)}
          </span>
        </PrimaryRow>
      </Container>
    );
  return null;
};

export const PaymentSummary: FC<Props> = ({ onCustomize }) => {
  const { dealSheet, hasPricingData } = useRiderComponent();

  if (!hasPricingData || !dealSheet) return <PaymentSummarySkeleton />;

  return (
    <PaymentSummaryDetails
      onCustomize={dealSheet.isSubmitted ? undefined : onCustomize}
      deal={dealSheet}
    />
  );
};
