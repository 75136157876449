import { getPartialDealSheetUpdateFromDealer } from '@digital-motors-boatyard/by-vessel-rider.component/dist/context/DealSheet/utils/getPartialDealSheetUpdateFromDealer';
import { getPartialDealSheetUpdateFromTenant } from '@digital-motors-boatyard/by-vessel-rider.component/dist/context/DealSheet/utils/getPartialDealSheetUpdateFromTenant';
import {
  DealSheetVehicle,
  InventoryVesselFull,
} from '@digital-motors-boatyard/common/dist';
import { Country } from '@digital-motors-boatyard/common/dist/enums';
import {
  DealerInterface,
  DealSheet,
} from '@digital-motors-boatyard/common/dist/interfaces';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import { getApplicableFees } from '@digital-motors-boatyard/common-frontend/dist/utility/pricing';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useEffect, useState } from 'react';

import { getDealSheet } from '../api/getDealSheet';
import { getVesselAssociations } from '../api/getVesselAssociations';
import { updateVehicle } from '../api/updateVehicle';
import { useAppData } from '../context/AppDataContext';
import { getOrCreateDealSheet } from '../context/DealSheet/utils/getOrCreateDealSheet';
import {
  getPartialDealSheetUpdateFromVessel,
  getPartialVehicleFromVessel,
} from '../context/DealSheet/utils/getPartialDealSheetUpdateFromVessel';
import { ModalError } from '../types';
import { BoatyardDealSheetDefaults, useBoatyard } from './useBoatyard';

const filterTruthy = (defaults: Partial<BoatyardDealSheetDefaults>) => {
  const filtered: Partial<BoatyardDealSheetDefaults> = {};
  Object.keys(defaults).forEach((key) => {
    const value = defaults[key as keyof BoatyardDealSheetDefaults];
    // @ts-ignore
    if (value) filtered[key] = value;
  });
  return filtered;
};

export const useGetDealSheet = ({
  dealer,
  vessel,
  dealSheetId,
}: {
  dealer: DealerInterface;
  vessel?: InventoryVesselFull;
  dealSheetId?: string;
}) => {
  const [state, setState] = useState<{
    dealSheet?: DealSheet;
    error?: ModalError;
    isBusy?: boolean;
  }>({});
  const [defaults, setDefaults] = useState<BoatyardDealSheetDefaults>();
  const { tenant } = useAppData();
  const boatyard = useBoatyard();

  if (
    boatyard?.vessel?.defaults &&
    !isEqual(defaults, boatyard.vessel.defaults)
  ) {
    setState({ dealSheet: undefined, error: undefined });
    setDefaults(boatyard.vessel.defaults);
  }

  useEffect(() => {
    if (
      (!defaults && !dealSheetId) ||
      state.isBusy ||
      state.dealSheet ||
      state.error
    ) {
      return;
    }

    setState({ dealSheet: undefined, error: undefined, isBusy: true });

    (async () => {
      const country = tenant.country as Country;
      const tenantDealSheetProps = defaults
        ? getPartialDealSheetUpdateFromTenant({
            tenant,
            condition: defaults.condition,
            vesselClass: defaults.vesselClass,
            dealerPrice: defaults.dealerPrice,
          })
        : null;
      const dealerDealSheetProps = getPartialDealSheetUpdateFromDealer(dealer);
      let vesselDealSheetProps: Partial<DealSheet> = {};
      let vesselVehicleProps: Partial<DealSheetVehicle> = {};
      if (vessel) {
        vesselDealSheetProps = getPartialDealSheetUpdateFromVessel(vessel);
        vesselVehicleProps = getPartialVehicleFromVessel(vessel);
      }

      const dealerFees = [
        ...getApplicableFees({
          vertical: tenant.vertical,
          dealer,
          vessel,
          condition: defaults?.condition,
        }),
        ...(defaults?.dealerFees?.map((fee, index) => {
          return { ...fee, id: index.toString() };
        }) || []),
      ];

      const partialDealSheet: Partial<DealSheet> = {
        ...dealerDealSheetProps,
        ...tenantDealSheetProps,
        ...(dealSheetId ? { id: dealSheetId } : null),
        ...(filterTruthy(
          pick(
            defaults,
            'financeType',
            'term',
            'downPayment',
            'creditRating',
            'additionalLeadData'
          )
        ) as Partial<DealSheet>),
        returnWebsiteUrl: defaults?.returnWebsiteUrl,
        ...(vesselVehicleProps.inventoryId
          ? vesselDealSheetProps
          : {
              ...pick(defaults, 'dealerId', 'dealerPrice'),
              dmSellingPrice: defaults?.dealerPrice,
              msrp: defaults?.basePrice,
              retailPrice: defaults?.basePrice,
              salesPrice: defaults?.dealerPrice,
              totalMsrp: defaults?.basePrice,
            }),
        vesselLength: {
          value: null,
          unit: null,
        },
        dealerFees,
        country,
        discounts: [],
        estimatedPayment: null,
        salesTax: 0,
        customModelIncentives: defaults?.customModelIncentives,
      };

      const vehicle: Partial<DealSheetVehicle> = {
        ...(vesselVehicleProps.inventoryId
          ? vesselVehicleProps
          : {
              ...pick(
                defaults,
                'year',
                'make',
                'model',
                'condition',
                'vesselClass'
              ),
              ...(filterTruthy(
                pick(defaults, 'mileage', 'hin', 'stockNumber', 'imageUrl')
              ) as Partial<DealSheetVehicle>),
              externalInventoryId: defaults?.customInventoryId,
            }),
        oemCodes: defaults?.oemCode || null,
      } as DealSheetVehicle;

      // If vessel data did not come from inventory service,
      // find make and model ID by name
      if (!partialDealSheet.id && !vehicle.inventoryId) {
        const associationsRes = await getVesselAssociations({
          vertical: tenant.vertical,
          year: defaults?.year as number,
          make: defaults?.make as string,
          model: defaults?.model as string,
          oemCode: defaults?.oemCode as string,
          ...(defaults?.engineManufacturer
            ? { engineManufacturer: defaults.engineManufacturer }
            : null),
        });
        if (associationsRes.type === STATUS_SUCCESS) {
          const { makeId, modelId, oemCode } = associationsRes.value;
          if (makeId) vehicle.makeId = makeId;
          if (modelId) vehicle.modelId = modelId;
          if (oemCode) vehicle.oemCodes = oemCode;
        }
      }

      // DRS-1155 We do not need to populate vehicle fields to partial dealsheet
      // during creation when we remove them and decouple them from creation process
      // in core-api. The vehicle will be provided optionally in updateVehicle()
      const createdDealSheet = await getOrCreateDealSheet({
        ...partialDealSheet,
        ...vehicle,
      });
      let dealSheet = null;
      if (createdDealSheet && createdDealSheet.id) {
        await updateVehicle(createdDealSheet.id, vehicle);
        const res = await getDealSheet(createdDealSheet.id);
        if (res.type === STATUS_SUCCESS) {
          dealSheet = res.value;
        }
      }

      if (dealSheet) {
        setState({ dealSheet, isBusy: false });
      } else {
        setState({
          error: partialDealSheet.id
            ? ModalError.DEAL_NOT_FOUND
            : ModalError.REQUEST_UNAVAILABLE,
          isBusy: false,
        });
      }
    })();
  }, [dealer, dealSheetId, defaults, state, tenant, vessel]);

  return state;
};
