"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DealBuilder = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var components_form_1 = require("@digital-motors-boatyard/components.form");
var ui_expandable_1 = require("@digital-motors-boatyard/ui.expandable");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var constants_1 = require("../../constants");
var Accessories_1 = require("../../context/Accessories");
var Dealer_1 = require("../../context/Dealer");
var DealSheet_1 = require("../../context/DealSheet");
var Pricing_1 = require("../../context/Pricing");
var ProtectionPlans_1 = require("../../context/ProtectionPlans");
var Tenant_1 = require("../../context/Tenant");
var useIsInverted_1 = require("../../hooks/useIsInverted");
var useScrollUpOnMount_1 = require("../../hooks/useScrollUpOnMount");
var styles_1 = require("../../styles");
var Accessories_2 = require("../accessories/Accessories");
var DealerInfo_1 = require("../DealerInfo");
var PillButton_1 = require("../PillButton");
var ProtectionPlans_2 = require("../protection/ProtectionPlans");
var Reservation_1 = require("../reservation/Reservation");
var SecondaryCtaButton_1 = require("../SecondaryCtaButton");
var SectionHeader_1 = require("../SectionHeader");
var Sekeleton_1 = require("../Sekeleton");
var TradeIn_1 = require("../trade-in/TradeIn");
var CustomizePayment_1 = require("./CustomizePayment");
var DiscountsSelector_1 = require("./DiscountsSelector");
var PaymentSummary_1 = require("./PaymentSummary");
var PaymentType_1 = require("./PaymentType");
var CommentWrapper = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), ui_theme_1.spacing.xs);
var DealBuilder = function (_a) {
    var _b, _c, _d;
    var onGetQuote = _a.onGetQuote, onClose = _a.onClose;
    var isInverted = (0, useIsInverted_1.useIsInverted)();
    var configuration = (0, Tenant_1.useTenant)().configuration;
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    var _e = (0, Pricing_1.usePricingContext)(), hasPricingData = _e.hasPricingData, dealSheetPricing = _e.dealSheetPricing;
    var _f = (0, Accessories_1.useAccessories)(), accessories = _f.accessories, loadingAccessories = _f.loadingAccessories;
    var _g = (0, ProtectionPlans_1.useProtectionPlans)(), protectionPlans = _g.protectionPlans, loadingProtectionPlans = _g.loadingProtectionPlans;
    var _h = (0, react_1.useState)(false), canPreselectProtectionPlans = _h[0], setCanPreselectProtectionPlans = _h[1];
    var discounts = (dealSheetPricing === null || dealSheetPricing === void 0 ? void 0 : dealSheetPricing.discounts) || [];
    var _j = (0, react_1.useState)(false), reserveOpen = _j[0], setReserveOpen = _j[1];
    var _k = (0, react_1.useState)(''), comment = _k[0], setComment = _k[1];
    (0, useScrollUpOnMount_1.useScrollUpOnMount)();
    var isSecondaryCtaEnabled = (0, react_1.useMemo)(function () {
        var _a, _b;
        return !!((_a = configuration === null || configuration === void 0 ? void 0 : configuration.secondaryCallToActionButton) === null || _a === void 0 ? void 0 : _a.text) &&
            !!((_b = configuration === null || configuration === void 0 ? void 0 : configuration.secondaryCallToActionButton) === null || _b === void 0 ? void 0 : _b.url);
    }, [configuration]);
    var tenantCrms = ((_b = (0, Tenant_1.useTenant)()) === null || _b === void 0 ? void 0 : _b.crms) || [];
    var dealerCrms = ((_c = (0, Dealer_1.useDealer)()) === null || _c === void 0 ? void 0 : _c.crms) || [];
    var crms = ((_d = (0, Tenant_1.useTenant)()) === null || _d === void 0 ? void 0 : _d.parallelDealerLeadSubmission)
        ? tenantCrms.concat(dealerCrms)
        : tenantCrms;
    var showComment = crms.filter(function (crm) { return crm.status && crm.channel !== enums_1.CrmChannel.API; }).length >
        0;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(SectionHeader_1.SectionHeader, { title: "Payment", stepText: "Step 1/2", onClose: onClose }),
        react_1.default.createElement(PaymentType_1.PaymentType, null),
        react_1.default.createElement(PaymentSummary_1.PaymentSummary, null),
        hasPricingData && dealSheet.financeType === constants_1.FINANCE && (react_1.default.createElement(CustomizePayment_1.CustomizePayment, null)),
        hasPricingData ? (react_1.default.createElement(styles_1.ExpandableGrid, null,
            (configuration === null || configuration === void 0 ? void 0 : configuration.web.global.showIncentives) && !!discounts.length && (react_1.default.createElement(ui_expandable_1.Expandable, { "data-testid": "DealBuilder__ExpandDiscount", label: "Discounts", inverted: isInverted },
                react_1.default.createElement(DiscountsSelector_1.DiscountsSelector, { discounts: discounts }))),
            (configuration === null || configuration === void 0 ? void 0 : configuration.web.vdp.showTradeInForm) && (react_1.default.createElement(ui_expandable_1.Expandable, { "data-testid": "DealBuilder__ExpandTradeIn", label: "Trade-In", inverted: isInverted },
                react_1.default.createElement(TradeIn_1.TradeIn, null))),
            (loadingAccessories || !!accessories.length) && (react_1.default.createElement(ui_expandable_1.Expandable, { "data-testid": "DealBuilder__ExpandAccessories", label: "Accessories", inverted: isInverted },
                react_1.default.createElement(Accessories_2.Accessories, null))),
            (loadingProtectionPlans || !!protectionPlans.length) && (react_1.default.createElement(ui_expandable_1.Expandable, { "data-testid": "DealBuilder__ExpandProtection", label: "Protection", inverted: isInverted, onChange: function (isOpen) { return setCanPreselectProtectionPlans(isOpen); } },
                react_1.default.createElement(ProtectionPlans_2.ProtectionPlans, { canPreselect: canPreselectProtectionPlans }))),
            (configuration === null || configuration === void 0 ? void 0 : configuration.web.vdp.showReservation) && (react_1.default.createElement(ui_expandable_1.Expandable, { "data-testid": "DealBuilder__ExpandReservation", label: "Reserve Now", inverted: isInverted, isOpen: reserveOpen, onChange: function (isOpen) { return setReserveOpen(isOpen); } },
                react_1.default.createElement(Reservation_1.Reservation, { onDismiss: function () { return setReserveOpen(false); } }))))) : (react_1.default.createElement(styles_1.ExpandableGrid, null,
            react_1.default.createElement(Sekeleton_1.SkeletonBox, { boxHeight: ui_theme_1.spacing.units(14) }),
            react_1.default.createElement(Sekeleton_1.SkeletonBox, { boxHeight: ui_theme_1.spacing.units(14) }),
            react_1.default.createElement(Sekeleton_1.SkeletonBox, { boxHeight: ui_theme_1.spacing.units(14) }),
            react_1.default.createElement(Sekeleton_1.SkeletonBox, { boxHeight: ui_theme_1.spacing.units(14) }))),
        showComment && (react_1.default.createElement(CommentWrapper, null,
            react_1.default.createElement(components_form_1.TextareaField, { label: 'Message', placeholder: "Questions or comments to dealer.", maxLength: 512, "data-testid": "Deal__Comment", value: comment, onChange: function (e) { return setComment(e.target.value); } }))),
        react_1.default.createElement(DealerInfo_1.DealerInfo, null),
        hasPricingData && (react_1.default.createElement(styles_1.ButtonWrapper, null,
            react_1.default.createElement(PillButton_1.PillButton, { "data-testid": "DealBuilder__GetQuoteButton", variant: "accent", inverted: isInverted, size: "m", onClick: function () { return onGetQuote(comment); } }, "Get Quote"),
            isSecondaryCtaEnabled && react_1.default.createElement(SecondaryCtaButton_1.SecondaryCtaButton, null)))));
};
exports.DealBuilder = DealBuilder;
var templateObject_1;
