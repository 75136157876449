import {
  LengthUnit,
  VesselClass,
} from '@digital-motors-boatyard/common/dist/apis';
import {
  Condition,
  FinanceType,
  ProviderLogo,
} from '@digital-motors-boatyard/common/dist/enums';

import { TenantProviderDataMap } from './types';

export const CLOUDINARY_CLOUDNAME = 'digital-motors-boatyard';

export const EMOTION_CACHE_KEY = 'drs';

export const DEFAULT_API_DEBOUNCE_TIME = 300;
export const DEFAULT_TOKEN_INTERVAL_TIME = 240000;

export const NEW = Condition.NEW;
export const USED = Condition.USED;

export const CASH = FinanceType.CASH;
export const FINANCE = FinanceType.FINANCE;

export const VESSEL_CLASSES = Object.values(VesselClass);
export const VESSEL_UNITS = Object.values(LengthUnit);
export const VESSEL_YEARS = Array.from(
  // 1984 through Current Year + 1
  { length: new Date().getFullYear() - 1982 },
  (v, k) => k + 1984
).reverse();

export const NULL_UNIT_VALUE = { unit: null, value: null };

export const RIDER_WIDTH = 480;
export const RIDER_HEADER_HEIGHT = 320;

export const DEFAULT_FINANCE_REQUIREMENTS = {
  minimumAmountFinanced: 0,
  maximumAmountFinanced: Infinity,
  maxAdvance: null,
};

export const DATE_FORMAT = 'MM / dd / yyyy';
export const DATE_FORMAT_SHORT = 'MM / yyyy';
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';

export const PHONE_HELPER_TEXT =
  'By continuing you will receive a one-time verification code to your phone number by SMS. Message and data rates may apply.';

export const UNIT_IMAGE_DEFAULT =
  'https://res.cloudinary.com/digital-motors-boatyard/defaults/unitImageDefault.png';

export const TENANT_PROVIDER_DATA: TenantProviderDataMap = {
  [ProviderLogo.ARI]: {
    logo: {
      url: 'https://res.cloudinary.com/digital-motors-boatyard/provider-logos/ari.png',
      alt: 'ARI Network Services',
    },
    salesPortalDomain: 'sales.arinet.paymentcalculator.app',
  },
  [ProviderLogo.DEALER_CAR_SEARCH]: {
    logo: {
      url: 'https://res.cloudinary.com/digital-motors-boatyard/provider-logos/dealer-car-search.png',
      alt: 'Dealer Car Search',
    },
    salesPortalDomain: 'sales.dealercarsearch.paymentcalculator.app',
  },
  [ProviderLogo.DEALER_SPIKE]: {
    logo: {
      url: 'https://res.cloudinary.com/digital-motors-boatyard/provider-logos/dealer-spike.png',
      alt: 'Dealer Spike',
    },
    salesPortalDomain: 'sales.dealerspike.paymentcalculator.app',
  },
  [ProviderLogo.INTERACT_RV]: {
    logo: {
      url: 'https://res.cloudinary.com/digital-motors-boatyard/provider-logos/interact-rv.png',
      alt: 'InteractRV',
    },
    salesPortalDomain: 'sales.interactrv.paymentcalculator.app ',
  },
  [ProviderLogo.NONE]: {
    logo: { url: '', alt: '' },
    salesPortalDomain: 'sales.paymentcalculator.app',
  },
};

export const LEAD_CALLBACK_USER_PROPS = [
  'firstName',
  'lastName',
  'phoneNumber',
  'contactEmail',
  'zipcode',
];

export const LEAD_CALLBACK_DEAL_PROPS = [
  'id',
  'stockNumber',
  'hin',
  'tenantName',
  'dealerName',
  'condition',
  'year',
  'make',
  'model',
  'imageUrl',
  'retailPrice',
  'totalMsrp',
  'dealerPrice',
  'dmSellingPrice',
  'salesPrice',
  'financeType',
  'estimatedPayment',
  'apr',
  'term',
  'creditRating',
  'downPayment',
  'amountDueAtSigning',
  'cashBack',
  'tradeInEstimate',
  'applicableTradeInValue',
  'country',
  'currency',
  'applicationDecision',
];
