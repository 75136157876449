"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentType = void 0;
var react_1 = __importStar(require("react"));
var constants_1 = require("../../constants");
var DealSheet_1 = require("../../context/DealSheet");
var Pricing_1 = require("../../context/Pricing");
var useChangeFinanceType_1 = require("../../context/Pricing/utils/useChangeFinanceType");
var useIsInverted_1 = require("../../hooks/useIsInverted");
var PillButton_1 = require("../PillButton");
var Sekeleton_1 = require("../Sekeleton");
var PaymentType = function () {
    var isInverted = (0, useIsInverted_1.useIsInverted)();
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    var changeFinanceType = (0, useChangeFinanceType_1.useChangeFinanceType)();
    var _a = (0, Pricing_1.usePricingContext)(), hasPricingData = _a.hasPricingData, dealSheetCalculations = _a.dealSheetCalculations;
    var financeType = dealSheet.financeType;
    var financeEnabled = dealSheetCalculations.financeEnabled;
    (0, react_1.useEffect)(function () {
        if (hasPricingData && financeType === constants_1.FINANCE && !financeEnabled) {
            changeFinanceType(constants_1.CASH);
        }
    }, [changeFinanceType, financeEnabled, financeType, hasPricingData]);
    if (!hasPricingData) {
        return react_1.default.createElement(Sekeleton_1.SkeletonPill, { "data-testid": "PaymentType__SkeletonPill" });
    }
    // If there is no finance pricing available, then the only
    // payment option is cash, so there is no need to render anything
    if (!financeEnabled)
        return null;
    return (react_1.default.createElement(PillButton_1.PillButtonGroup, null,
        react_1.default.createElement(PillButton_1.PillButton, { "data-testid": "PaymentType__Finance", active: financeType === constants_1.FINANCE, onClick: function () { return changeFinanceType(constants_1.FINANCE); }, inverted: isInverted }, "Finance"),
        react_1.default.createElement(PillButton_1.PillButton, { "data-testid": "PaymentType__Cash", active: financeType === constants_1.CASH, onClick: function () { return changeFinanceType(constants_1.CASH); }, inverted: isInverted }, "Cash")));
};
exports.PaymentType = PaymentType;
