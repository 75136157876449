"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamsFromDealSheet = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var constants_1 = require("../../constants");
var getParamsFromDealSheet = function (dealSheet) {
    if (!(dealSheet === null || dealSheet === void 0 ? void 0 : dealSheet.id))
        return {};
    var vehicle = dealSheet.vehicle;
    var financeType = dealSheet.financeType, tradeIn = dealSheet.tradeInEstimate, creditRating = dealSheet.creditRating;
    var discounts = (dealSheet.discounts || []).filter(function (i) { return i.incentiveType === enums_1.IncentiveType.DISCOUNT; });
    var isFinance = financeType === constants_1.FINANCE;
    return {
        deal_uuid: dealSheet.id,
        deal_hin: vehicle === null || vehicle === void 0 ? void 0 : vehicle.hin,
        deal_payment_type: financeType,
        deal_year: (vehicle === null || vehicle === void 0 ? void 0 : vehicle.year) ? vehicle.year.toString() : '',
        deal_make: vehicle === null || vehicle === void 0 ? void 0 : vehicle.make,
        deal_model: vehicle === null || vehicle === void 0 ? void 0 : vehicle.model,
        deal_image_url: vehicle === null || vehicle === void 0 ? void 0 : vehicle.imageUrl,
        deal_vessel_uuid: vehicle === null || vehicle === void 0 ? void 0 : vehicle.inventoryId,
        deal_vessel_condition: vehicle === null || vehicle === void 0 ? void 0 : vehicle.condition,
        deal_vessel_msrp: dealSheet.msrp,
        deal_vessel_dealer_price: dealSheet.dealerPrice,
        deal_credit_tier: creditRating,
        deal_stock_no: vehicle === null || vehicle === void 0 ? void 0 : vehicle.stockNumber,
        deal_loan_term: isFinance ? dealSheet.term : null,
        deal_loan_apr: isFinance ? dealSheet.apr : null,
        deal_loan_downpayment: isFinance ? dealSheet.downPayment : null,
        deal_discount_names: discounts.length > 0 ? discounts.map(function (d) { return d.name; }).join(', ') : null,
        deal_discount_ids: discounts.length > 0
            ? discounts.map(function (d) { return d.incentiveId; }).join(', ')
            : null,
        deal_discount_amounts: discounts.length > 0 ? discounts.map(function (d) { return d.amount; }).join(', ') : null,
        dealer_name: dealSheet.dealerName ? dealSheet.dealerName : null,
        request_showing_date: dealSheet.showingDate,
        request_showing_time_of_day: dealSheet.showingTimeOfDay,
        trade_in_year: (tradeIn && tradeIn.year) || null,
        trade_in_make: (tradeIn && tradeIn.make) || null,
        trade_in_model: (tradeIn && tradeIn.model) || null,
        trade_in_condition: (tradeIn && tradeIn.condition) || null,
        trade_in_estimate: (tradeIn && tradeIn.estimate) || null,
    };
};
exports.getParamsFromDealSheet = getParamsFromDealSheet;
