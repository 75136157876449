"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetMonthlyCostForPrice = exports.getMonthlyCostForPrice = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var pricing_1 = require("@digital-motors-boatyard/common-frontend/dist/utility/pricing");
var math_utilities_1 = require("@digital-motors-boatyard/math-utilities");
var DealSheet_1 = require("../context/DealSheet");
var Pricing_1 = require("../context/Pricing");
var getMonthlyCostForPrice = function (_a) {
    var dealSheet = _a.dealSheet, loan = _a.loan, dealSheetPricing = _a.dealSheetPricing, dealSheetCalculations = _a.dealSheetCalculations, price = _a.price, condition = _a.condition;
    var dmSellingPrice = dealSheet.dmSellingPrice, financeType = dealSheet.financeType, discounts = dealSheet.discounts, customModelIncentives = dealSheet.customModelIncentives, fniProducts = dealSheet.fniProducts, accessories = dealSheet.accessories, taxRate = dealSheet.taxRate;
    var calculatedDownPayment = dealSheetCalculations.calculatedDownPayment, applicableTradeInValue = dealSheetCalculations.applicableTradeInValue, dealerFeesSum = dealSheetCalculations.dealerFeesSum;
    var paymentParams = {
        fniProducts: fniProducts,
        basePrice: condition === enums_1.Condition.USED
            ? dealSheet.retailPrice
            : dealSheet.totalMsrp,
        dmSellingPrice: dmSellingPrice,
        financeType: financeType,
        discounts: discounts,
        customModelIncentives: customModelIncentives,
        accessories: accessories,
        taxRate: taxRate,
        loan: loan,
        calculatedDownPayment: calculatedDownPayment || 0,
        applicableTradeInValue: applicableTradeInValue,
        dealerFeesSum: dealerFeesSum,
        lenderRequirements: dealSheetPricing === null || dealSheetPricing === void 0 ? void 0 : dealSheetPricing.lenderRequirements,
    };
    var basePayment = (0, pricing_1.getEstimatedMonthlyPayment)(paymentParams);
    var increasedPayment = (0, pricing_1.getEstimatedMonthlyPayment)(__assign(__assign({}, paymentParams), { dmSellingPrice: (0, math_utilities_1.calculateArraySum)([dmSellingPrice, price]) }));
    var priceDelta = basePayment !== null && increasedPayment !== null
        ? increasedPayment - basePayment
        : null;
    return priceDelta === null ? null : (0, math_utilities_1.preciseNumber)(priceDelta, 2);
};
exports.getMonthlyCostForPrice = getMonthlyCostForPrice;
var useGetMonthlyCostForPrice = function (price) {
    var _a = (0, DealSheet_1.useDealSheet)(), dealSheet = _a.dealSheet, loan = _a.loan;
    var vehicle = (0, DealSheet_1.useDealSheetVehicle)().vehicle;
    var condition = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.condition) || null;
    var _b = (0, Pricing_1.usePricingContext)(), dealSheetPricing = _b.dealSheetPricing, dealSheetCalculations = _b.dealSheetCalculations;
    return (0, exports.getMonthlyCostForPrice)({
        dealSheet: dealSheet,
        dealSheetPricing: dealSheetPricing,
        dealSheetCalculations: dealSheetCalculations,
        loan: loan,
        price: price,
        condition: condition,
    });
};
exports.useGetMonthlyCostForPrice = useGetMonthlyCostForPrice;
